<template>

  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-edit
          variant="danger"
          class="btn-sm"
          @click="resetModal(true)"
        >
          {{ $t('Add') }}
        </b-button>
      </div>
    </b-col>
    <b-col
      cols="12"
      class="form_custom"
    >
      <b-card-code title="">
        <b-row>
          <b-col cols="6">
            <b-button
              variant="outline-danger"
              class="position-absolute position-bottom-0 btn-sm"
              :disabled="!ids.length"
              @click="deleteMulti"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Delete') }}</span>
            </b-button>
          </b-col>
          <b-col md="3" />
          <b-col md="3">
            <label for="">
              {{ $t("Search") }}
            </label>
            <b-form-input
              v-model="filter.key"
              :placeholder="$t('search_key')"
              @change="loadItems"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <table
              class="table b-table"
              style="margin-top: 10px"
            >
              <thead>
                <tr>
                  <th width="10">
                    <b-form-checkbox
                      v-model="cbxAll"
                      class="custom-control-danger"
                      name="check-button"
                      @change="val => toggleSelectAll(val)"
                    />
                  </th>
                  <th
                    scope="col"
                    width="10"
                  >
                    STT
                  </th>
                  <th
                    scope="col"
                    width="400"
                  >
                    Tên lớp
                  </th>
                  <th
                    scope="col"
                    width="200"
                  >Số buổi</th>
                  <th
                    width="100"
                    scope="col"
                  >
                    Ngày khai giảng
                  </th>
                  <th>
                    Đường dẫn
                  </th>
                  <th width="10">
                    Thao tác
                  </th>
                </tr>
              </thead>
              <template v-if="rows.length">
                <template v-if="isLoading">
                  <tr
                    v-for="(item) in rows"
                  >
                    <td colspan="1">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="4">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="2">
                      <b-skeleton animation="fade" />
                    </td>
                  </tr>
                </template>
                <tr
                  v-for="(item, index) in rows"
                  v-else
                  :id="item.id"
                  :key="item.id"
                >
                  <td>
                    <b-form-checkbox
                      v-model="ids"
                      :value="item.id"
                      class="custom-control-danger"
                      name="check-button"
                    />
                  </td>
                  <td scope="row">
                    {{ index + 1 }}
                  </td>
                  <td width="250">
                    <span
                      v-b-modal.modal-edit
                      @click="resetModal(false, item)"
                    >
                      {{ item.name }}
                    </span>
                  </td>
                  <td width="50"> <span
                    class="text-name"
                    v-html="item.number_of_sessions"
                  /> buổi</td>
                  <td width="200">{{ item.opening_day }}</td>
                  <td>{{ item.link }}</td>
                  <td>
                    <b-dropdown
                      id="dropdown-1"
                      class="custom-position-dropdown"
                      variant="flat-sencondary"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle"
                        />
                      </template>
                      <div
                        v-b-modal.modal-edit
                        class="dropdown-item"
                        @click="resetModal(false, item)"
                      >
                        {{ $t('edit') }}
                      </div>
                      <div
                        class="dropdown-item"
                        @click="deleteMulti(item.id)"
                      >{{ $t('Delete') }}</div>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <tr
                v-else
              >
                <td colspan="6">
                  <p class="text-center">
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </table>
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              class="pagination-danger mt-2"
              align="right"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template #next-text>
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>

      </b-card-code>
      <b-modal
        id="modal-edit"
        v-model="modalShow"
        :ok-title="$t('save_info')"
        ok-variant="danger"
        :cancel-title="$t('cancel')"
        centered
        size="lg"
        :title="titleAdd"
        @ok.prevent="submit "
      >
        <validation-observer ref="addOpening">
          <b-form class="form_custom">
            <b-row>
              <b-col md="8">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('name_q')"
                    rules="required"
                  >
                    <label :class="errors.length > 0 ? 'text-danger': null">
                      {{ $t('name_q') }} <span class="text-danger">(*)</span></label>
                    <b-form-input
                      id="name"
                      v-model="frm.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="number_of_sessions"
                    rules="required"
                  >
                    <label :class="errors.length > 0 ? 'text-danger': null">
                      số buổi <span class="text-danger">(*)</span></label>
                    <b-form-input
                      v-model="frm.number_of_sessions"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Đường dẫn"
                    rules="required"
                  >
                    <div>
                      <label :class="errors.length > 0 ? 'text-danger': null">
                        Đường dẫn <span class="text-danger">(*)</span></label>
                      <b-form-input
                        id="link"
                        v-model="frm.link"
                        placeholder="Nhập đường dẫn"
                        rows="4"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Ngày khai giảng"
                    rules="required"
                  >
                    <div>
                      <label :class="errors.length > 0 ? 'text-danger': null">
                        Ngày khai giảng<span class="text-danger">(*)</span></label>
                      <flat-pickr
                        v-model="frm.opening_day"
                        class="form-control"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BFormInput,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BListGroup,
  BForm,
  BImg,
  BFormFile,
  BModal,
  BFormRadio,
  BInputGroupAppend,
  BBadge,
  BListGroupItem,
  BFormSelect,
  BFormTextarea,
  BSkeleton,
  BEmbed,
  VBTooltip,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    flatPickr,
    BImg,
    vSelect,
    BSkeleton,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    draggable,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      cbxAll: false,
      modalShow: false,
      currentPage: 1,
      showAddAnswer: false,
      frm: {
        name: null,
        number_of_sessions: null,
        opening_day: null,
        link: null,
      },
      pageLength: 10,
      isShow: true,
      editModal: false,
      dragging: false,
      titleAdd: this.$t('Add'),
      // eslint-disable-next-line global-require
      required,
      isLoading: false,
      total: 0,
      rows: [],
      ids: [],
      modalName: '',
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      filter: {
        key: '',
      },
      sort: {
        by: 'order',
        direction: 'desc',
      },

    }
  },
  watch: {
    ids: {
      handler(value) {
        this.cbxAll = value.length && this._.difference(this.rows.map(v => v.id), value).length === 0
      },
    },
  },
  created() {
    this.loadItems()
  },
  methods: {
    deleteMulti(id) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          if (typeof id === 'number') this.ids = [id]
          this.$http.delete('/opening', {
            data: {
              ids: this.ids,
            },
          })
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.delete_success'), {
                title: this.$t('message.noitify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              this.loadItems()
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              console.log('e', e)
              this.$bvToast.toast(this.$t('message.delete_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
              this.ids = []
            })
        }
      })
    },
    toggleSelectAll(value) {
      if (value) {
        this.ids = this.rows.map(v => v.id)
      } else {
        this.ids = []
      }
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadItems()
    },
    loadItems() {
      this.isLoading = true
      this.$http.get('/opening', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          keyword: this.filter.key,
          order_by: this.sort.by,
          order_direction: this.sort.direction === 'ascending' ? 'asc' : 'desc',
        },
      })
        .then(res => {
          this.total = res.data.total
          this.pageLength = res.data.per_page
          this.rows = res.data.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },

    resetModal(flag, opening = false) {
      this.titleAdd = flag ? this.$t('Add') : this.$t('Edit')
      // eslint-disable-next-line no-multi-assign
      this.frm.name = null
      this.frm.number_of_sessions = null
      this.frm.opening_day = null
      this.frm.link = null
      this.editModal = false
      if (opening) {
        this.frm.name = opening.name
        this.frm.number_of_sessions = opening.number_of_sessions
        this.frm.opening_day = opening.opening_day
        this.frm.link = opening.link
        this.editModal = opening.id
      }
    },
    convertDate(iosDate) {
      if (!iosDate) return ''
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
    submit() {
      this.$refs.addOpening.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()
          formData.append('name', this.frm.name)
          formData.append('number_of_sessions', this.frm.number_of_sessions)
          formData.append('opening_day', this.frm.opening_day)
          formData.append('link', this.frm.link)

          const url = this.editModal ? `/opening/${this.editModal}` : '/opening'
          if (this.editModal) formData.append('_method', 'PUT')

          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.modalShow = false
              this.loadItems()
              this.resetModal()
              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              console.log('error', error.response)
              let errorMsg = {}
              if (error.response.status === 422) {
                errorMsg = error.response.data
              } else {
                errorMsg.name = [error.response.data.error]
              }
              this.$refs.addOpening.setErrors(errorMsg)
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
